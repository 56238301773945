import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import Layout from '../../components/layout'
import ContactForm from '../../components/contact-form'

const ContactBlock = ({ title, children }) => (
  <div className="col-lg-6 col-md-6 col-sm-6 col-12 mb--40">
    <div className="rn-address">
      <div className="inner">
        <h4 className="title">{title}</h4>
        {children}
      </div>
    </div>
  </div>
)

const ContactPage = ({ data }) => {
  const [showForm, setShowForm] = useState(true)

  const {
    frontmatter: { title, seoTitle, catchphrase, contactBlocks, messages },
  } = data.markdownRemark

  const onSuccess = () => setShowForm(false)

  return (
    <Layout title={title} seoTitle={seoTitle}>
      <div className="rn-contact-area rn-section-gap">
        <div className="container">
          <div className="row row--35 justify-content-md-center">
            <div className="col-lg-6">
              <div className="section-title text-left mb--50">
                <h2 className="title text-center">{catchphrase}</h2>
              </div>
              <div>
                <div className="rn-contact-address-area">
                  <div className="row mt_dec--40">
                    {contactBlocks.map(({ title, content }, index) => (
                      <ContactBlock
                        title={title}
                        key={`contact_block_${index}`}
                      >
                        <div
                          dangerouslySetInnerHTML={{ __html: content }}
                        ></div>
                      </ContactBlock>
                    ))}
                  </div>
                </div>
              </div>
              <div>
                {showForm ? (
                  <ContactForm origin="contact-page" successFn={onSuccess} />
                ) : (
                  <div className="alert alert-success">{messages.success}</div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default ContactPage

ContactPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export const query = graphql`
  query ContactPageQuery {
    markdownRemark(frontmatter: { templateKey: { eq: "contact-page" } }) {
      frontmatter {
        title
        catchphrase

        contactBlocks {
          title
          content
        }

        messages {
          success
        }
      }
    }
  }
`
